body {
  padding-top: 65px;
}
.header {
  position: fixed;
  background-color: pink;
  width: 100%;
  text-align: center;
  height: 50px;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99;
  font-weight: 700;
}

.header .prev {
  position: absolute;
  z-index: 100;
  top: 0;
  left: 10px;
  line-height: 50px;
  font-size: 30px;
  cursor: pointer;
}

.subscribersList {
  font-size: 0.8em;
}

.noBorder {
  border-top: 0 !important;
}
.list-group .list-group-item {
  color: black;
  cursor: pointer;
}

.list-group .list-group-item:hover {
  color: black !important;
  text-decoration: none;
  background-color: #ccc !important;
}

table tr td {
  vertical-align: middle;
}

.danger {
  background-color: #f5c6cb !important;
  /* text-decoration: line-through; */
}

.warning {
  background-color: #ffeeba !important;
}
